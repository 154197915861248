// @flow
import Swiper from 'swiper';
import breakpoints from './../../assets/js/breakpoints';

const selectorRoot = '.o-section-comments';

/**
 * Ini slick gallery for this component ...
 * @param {HTMLElement} root The iframe root element
 */
function iniGallery(root: HTMLElement) {
	const gallerySlideshow = root.querySelector('.o-section-comments__slider');

	const swiper = new Swiper(gallerySlideshow, {
		// Optional parameters
		direction: 'horizontal',
		loop: true,
		speed: 600,
		slidesPerView: 3,
		spaceBetween: 0,
		autoHeight: true,
		longSwipesRatio: 0.2,
		slideActiveClass: 'active',

		// Navigation
		navigation: {
			nextEl: '.o-section-comments__slider-next',
			// prevEl: '.swiper-button-prev',
		},

		// Pagination
		pagination: {
			el: '.m-pagination-bullets',
			type: 'bullets',
			bulletElement: 'span',
			clickable: true,
			bulletClass: 'm-pagination-bullets__bullet',
			bulletActiveClass: 'active',
			currentClass: 'current',
			clickableClass: 'clickable',
		},

		autoplay: {
			delay: 4000,
		},

		breakpoints: {
			// when window width is <= breakpoints.desktop
			[breakpoints.desktop]: {
				slidesPerView: 1,
				// spaceBetween: 0,
			},

			[breakpoints.wide]: {
				slidesPerView: 2,
				// spaceBetween: 0,
			},
		},
	});
}

export default function ini(root: HTMLElement | Document = document) {
	root.querySelectorAll(selectorRoot).forEach(element => {
		iniGallery(element);
	});
}
