// @flow
import Swiper from 'swiper';

const selectorRoot = '.o-header-slides';

/**
 * Ini slick gallery for this component ...
 * @param {HTMLElement} root The iframe root element
 */
function iniGallery(root: HTMLElement) {
	const gallerySlideshow = root.querySelector('.o-header-slides__gallery');
	// const prevArrow = root.querySelector('.m-control-slider__prev');
	// const nextArrow = root.querySelector('.m-control-slider__next');
	// const currentCount = root.querySelector(
	// 	'.m-control-slider__pagination-current'
	// );
	// const totalCount = root.querySelector('.m-control-slider__pagination-total');

	// if (!prevArrow || !nextArrow || !currentCount || !totalCount) {
	// 	throw new Error('Missing elements to initialize slider');
	// }

	// function updateCount(currentSlide: number, totalSlides: number) {
	// 	currentCount.innerHTML = String(currentSlide).padStart(2, '0');
	// 	totalCount.innerHTML = String(totalSlides).padStart(2, '0');
	// }

	const swiper = new Swiper(gallerySlideshow, {
		// Optional parameters
		direction: 'horizontal',
		loop: true,
		speed: 600,
		slidesPerView: 1,
		longSwipesRatio: 0.2,

		slideActiveClass: 'active',

		// Pagination
		pagination: {
			el: '.m-pagination-bullets',
			type: 'bullets',
			bulletElement: 'span',
			clickable: true,
			bulletClass: 'm-pagination-bullets__bullet',
			bulletActiveClass: 'active',
			currentClass: 'current',
			clickableClass: 'clickable',
		},

		autoplay: {
			delay: 6000,
		},

		on: {
			init() {
				// because of we are using loop: true, total length is - 2
				// updateCount(this.realIndex + 1, this.slides.length - 2);
			},
			slideChange() {
				// because of we are using loop: true, total length is - 2
				// updateCount(this.realIndex + 1, this.slides.length - 2);
			},
		},
	});
}

export default function ini(root: HTMLElement | Document = document) {
	root.querySelectorAll(selectorRoot).forEach(element => {
		iniGallery(element);
	});
}
