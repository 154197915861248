// @flow
import iniOHeaderSlides from './../../_header/js/OHeaderSlides';
import iniOSectionComments from './../../home/js/OSectionComments';
import iniOSectionEvents from './../../event/js/OSectionEvents';

// ini on load
window.addEventListener('DOMContentLoaded', () => {
	iniOHeaderSlides(document);
	iniOSectionComments(document);
	iniOSectionEvents(document);

	console.log('%cSite home is ready', 'background: #8be09f; color: #39485e');
});
